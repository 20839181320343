import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, graphql } from 'gatsby';
import DOMPurify from 'isomorphic-dompurify';
import { find } from 'lodash';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import { Header, InternalContainer, PageContent } from './basic-styles';

import HorizontalSecondaryMenu from '../components/common/horizontal-secondary-menu';
import { Link18 } from '../components/common/link';
import { NavItem } from '../types/cms/models/nav-item';
import PageHeader from '../components/common/page-header';
import PublicLayout from '../layout/public-layout';
import { formatDate, formatDateRangeToMonthDayDayYear, getTimeZoneByName } from '../utils/date';
import { getRealNodes } from '../utils/contentFilter';
import { navigateTo } from '../state/actions/routing';
import { useI18NContext } from '../i18n';

type StudentAndParentProgramsProps = {
  data: {
    eventType: {
      translation: {
        value: string;
        upcoming_event_title: string;
        read_more: string;
      };
    };
    topNavItems: {
      nodes: TypeNavItem[];
    };
    activeNavItem: any;
    activeSideNavItem: any;
    signatureEvents: {
      translation: {
        body: string;
        title: string;
      };
      type: {
        translation: {
          value: string;
        };
        name: string;
        slug: string;
      };
    };
    events: {
      nodes: any[];
    };
  };
};

type TypeNavItem = {
  slug: string;
  name: string;
  translation: {
    value: string;
  };
};

const StyledHeader = styled(Header)`
  padding: 0;
`;

const StyledUpcomingEvents = styled.h4`
  padding: 32px 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: -0.4px;
  border-top: 1px solid #ddd;
  color: #000;
`;

const EventImageWrapper = styled.div`
  margin-right: 15px;
`;

const EventImage = styled.img`
  // width: 272px;
  width: 100%;
`;

const StyledH6 = styled.h6`
  font-size: 22px;
  color: #000;
  letter-spacing: -0.4px;
  line-height: 38px;
  font-weight: bold;
`;

const StyledH5 = styled.h5`
  font-size: 18px;
  color: #000;
  font-weight: bold;
`;

const StyledP = styled.p`
  font-size: 18px;
  color: #000;
  letter-spacing: 0;
  line-height: 28px;
`;

const EventLocation = styled(StyledP)`
  margin-bottom: 1rem;
`;

const StyledDescription = styled(StyledP)`
  margin-top: 1rem;
`;

const StyledRow = styled(Row)`
  padding-bottom: 15px;
  padding-top: 15px;
`;

// The query used to provide the page data
export const query = graphql`
  query($type: String!, $eventType: Int!, $language: String) {
    eventType: directusEventsType(directusId: { eq: $eventType }) {
      directusId
      translation(language: $language) {
        value
        upcoming_event_title
        read_more
      }
    }

    signatureEvents: directusEventPage(type: { name: { eq: $type } }) {
      translation(language: $language) {
        title
        body
      }
      type {
        translation(language: $language) {
          value
        }
        name
        slug
        type
      }
    }

    topNavItems: allDirectusEventPageType(filter: { type: { eq: $eventType }, status: { eq: "published" } }) {
      nodes {
        name
        translation(language: $language) {
          value
        }
        slug
        type
      }
    }

    events: allDirectusEvent(
      filter: { page: { name: { eq: $type } }, type: { directusId: { eq: $eventType } }, status: { eq: "published" } }
    ) {
      nodes {
        slug
        id: directusId
        name
        type {
          directusId
          name
        }
        page {
          slug
        }
        start_date_time
        end_date_time
        event_timezone {
          timezone_value
        }

        translation(language: $language) {
          name
          description
        }

        location
        image {
          data {
            full_url
          }
        }
      }
    }
  }
`;
const positionDescriptionLength = 200;

const StudentAndParentPrograms = ({
  data: { eventType, topNavItems, signatureEvents, events },
}: StudentAndParentProgramsProps) => {
  const { translateSlug } = useI18NContext();
  const header = eventType.translation.value;
  const readMore = eventType.translation.read_more;
  const dispatch = useDispatch();
  const mappedMenuItems: NavItem[] = getRealNodes(topNavItems).map((item: TypeNavItem) => {
    return {
      value: item.translation.value,
      name: item.name,
      slug: item.slug,
    };
  });

  const activeTypeItem: NavItem = {
    value: signatureEvents.type.translation.value,
    name: signatureEvents.type.name,
    slug: signatureEvents.type.slug,
  };

  const eventItems = getRealNodes(events);

  const handleSecondaryMenuClick = (e: any) => {
    const activeItem = find(mappedMenuItems, item => {
      return item.name === e.key;
    });

    if (activeItem) {
      dispatch(navigateTo(translateSlug(activeItem.slug)));
    }
  };

  const upcomingEvents = eventItems.length
    ? events.nodes.filter((e: any) => !dayjs(e.end_date_time).isBefore(dayjs()))
    : [];

  const upcomingEventsSortByDate = upcomingEvents.sort(
    (a, b) => Date.parse(a.start_date_time) - Date.parse(b.start_date_time)
  );

  return (
    <PublicLayout seoTitle="Student & Parent Programs">
      <InternalContainer>
        <Row justify="space-between">
          <Col xs={24}>
            <PageHeader
              title=""
              breadcrumb={[
                <Link key="1" to={translateSlug('/')}>
                  Home
                </Link>,
                `${header}`,
              ]}
              fullWidth
              isResponsive
              noShadow
            />
            <StyledHeader>{header}</StyledHeader>
          </Col>
        </Row>
        <HorizontalSecondaryMenu
          activeItem={activeTypeItem}
          handleClick={handleSecondaryMenuClick}
          items={mappedMenuItems}
        />
        <Row justify="space-between">
          {signatureEvents ? (
            <Col xs={24}>
              <Header>{signatureEvents.translation.title}</Header>
              <PageContent
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(signatureEvents.translation.body, { ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] }),
                }}
              />
            </Col>
          ) : null}
        </Row>
        <Row>
          {upcomingEventsSortByDate.length ? (
            <Col xs={24}>
              <StyledUpcomingEvents>{eventType.translation.upcoming_event_title}</StyledUpcomingEvents>
              {upcomingEventsSortByDate.map((event: any, i: any) => (
                <StyledRow key={i}>
                  <Col xs={24}>
                    <StyledH6>{event.translation.name}</StyledH6>
                  </Col>
                  <Col xs={24}>
                    <EventLocation>
                      {event.location && event.location.includes('http') ? (
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={event.location}
                        >
                          Online Event
                        </a>
                      ) : (
                        event.location
                      )}
                    </EventLocation>
                  </Col>
                  <Col xs={24} md={6}>
                    <EventImageWrapper>
                      <EventImage src={event.image.data.full_url} />
                    </EventImageWrapper>
                  </Col>
                  <Col xs={24} md={18}>
                    <StyledH5>
                      {formatDateRangeToMonthDayDayYear(event.start_date_time, event.end_date_time, { month: 'MMM' })}
                    </StyledH5>
                    <StyledP>
                      {`${formatDate(event.start_date_time, 'hh:mma')} -
                      ${formatDate(event.end_date_time, 'hh:mma')} ${getTimeZoneByName(
                        event?.event_timezone?.timezone_value
                      )?.abbr || ''}`}
                    </StyledP>
                    <StyledDescription
                      dangerouslySetInnerHTML={{
                        __html:
                          event.translation.description.length > positionDescriptionLength
                            ? DOMPurify.sanitize(
                                `${event.translation.description.slice(0, positionDescriptionLength)}...`
                              )
                            : DOMPurify.sanitize(event.translation.description),
                      }}
                    />
                    <Link18 to={event.slug} isI18N>
                      {readMore}
                    </Link18>
                  </Col>
                </StyledRow>
              ))}
            </Col>
          ) : null}
        </Row>
      </InternalContainer>
    </PublicLayout>
  );
};

export default StudentAndParentPrograms;
